<template>
  <ion-page>
    <ion-content class="page" :fullscreen="true">
      <div v-if="!isLogin">
        <div class="login-title">
          您还未登录，登录后才可以查看
        </div>
        <div>
          <a class="login-button" href="/login">登录</a>
        </div>
      </div>
      <div v-if="isLogin">
        <ion-list id="list" class="list-container">
          <ion-item detail="false" class="order" v-for="item in billList" v-bind:key="item.id">
            <div>
              <div class="order-no">账单编号：{{ item.orderNo }}</div>
              <div class="order-shop-info">
                <div class="order-shop-sub-info">
                  <div class="order-no">充值金额：{{ item.amount }}</div>
                  <div class="order-status"
                       v-bind:class="[item.payStatus===0 ? 'unpayed-background-color' : 'eleme-background-color']">
                    支付状态：{{ $filters.billStatus(item.payStatus) }}
                  </div>
                </div>
              </div>
              <div class="order-time-info">
                <div class="order-submit-time">下单时间：{{ $filters.formatOrderDate(item.gmtCreate) }}</div>
              </div>
            </div>
          </ion-item>
        </ion-list>
        <div class="loading complete" :hidden="!searchLoadingComplete">我是有底线的</div>
        <ion-infinite-scroll ref="infinite-scroll" style="height: 10px;"
                             threshold="20px"
                             id="infinite-scroll"
                             @ionInfinite="searchScrollLower($event)"
        >
          <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="正在加载更多...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonPage} from '@ionic/vue';
import {getBillList} from "../api/bill";

export default {
  name: 'Tab2',
  components: {
    IonContent, IonPage, IonItem, IonList, IonInfiniteScroll, IonInfiniteScrollContent
  },
  data() {
    return {
      isLogin: true,
      page: 1,
      size: 5,
      longitude: null,
      latitude: null,
      isFromSearch: true,   // 用于判断searchSongList数组是不是空数组，默认true，空的数组
      searchLoading: false, //"上拉加载"的变量，默认false，隐藏
      searchLoadingComplete: false,  //“没有数据”的变量，默认false，隐藏
      indicatorDots: true,
      list: [],
      selected: 0,
      billList: [],
      timeouts: [],
      countDownList: {},
      countDownDisplayList: {},
    }
  },
  ionViewDidEnter() {
    console.log("进入订单列表")
    this.$refs["infinite-scroll"].$el.disabled = false;
    this.onShow();
  },
  methods: {
    onShow: function () {
      this.page = 1;   //第一次加载，设置1
      this.billList = [];  //放置返回数据的数组,设为空
      this.isFromSearch = true;  //第一次加载，设置true
      this.searchLoading = false;  //把"上拉加载"的变量设为false，不显示
      this.searchLoadingComplete = false //把“没有数据”设为false，隐藏
      this.renderBillList();
    },
    //页面上拉触底事件的处理函数
    searchScrollLower: function ($event) {
      console.log("上拉触底");
      if (!this.searchLoading && !this.searchLoadingComplete) {
        this.page = this.page + 1;  //每次触发上拉事件，把searchPageNum+1
        this.searchLoading = true;   //把"上拉加载"的变量设为false，显示
        this.isFromSearch = false;  //触发到上拉事件，把isFromSearch设为为false
        this.renderBillList();
        $event.target.complete();
      }
      if (this.searchLoadingComplete) {
        $event.target.disabled = true;
      }
    },
    renderBillList: function () {
      console.log('app---getBillList');
      console.log(this.timeouts);
      for (let i = 0; i < this.timeouts.length; i++) {
        clearInterval(this.timeouts[i]);
      }
      getBillList({limit: this.size, page: this.page}).then(res => {
        let resList = [];
        if (res.data.length === 0) {
          this.searchLoadingComplete = true;   //把"我是有底线的"的变量设为false，不显示
          this.searchLoading = false;   //把"上拉加载"的变量设为false，不显示
        } else {
          this.searchLoadingComplete = false;   //把"我是有底线的"的变量设为false，不显示
          this.searchLoading = false;   //把"上拉加载"的变量设为false，不显示
        }
        //如果isFromSearch是true从data中取出数据，否则先从原来的数据继续添加
        resList = this.billList.concat(res.data)
        console.log(res.data.records);
        this.billList = resList; //获取数据数组
      }).catch(res => {
        console.log(res.data);
        if (res.data.code === 401) {
          this.isLogin = false;
        } else if (res.data.code === 200) {
          this.isLogin = true;
        }
      });
    },
    gotoOrderDetail: function (orderId) {
      this.$router.push({
        path: '/orderDetail', query: {orderId: orderId}
      });
    }
  }
}
</script>
<style lang="css">
/* pages/order/list.wxss */
.page {
  --ion-background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

ion-item {
  --ion-background-color: white;
  --border-style: none;
  --background-activated: none;
}

.list-container {
  margin-top: 5px;
}

.login-title {
  font-size: 20px;
  display: block;
  width: 300px;
  height: 50px;
  margin: 50px auto;
}

.login-button {
  display: block;
  width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  background-color: #d81e06;
  color: white;
  font-weight: bold;
  text-decoration-line: none;
}

.order {
  display: flex;
  margin: 5px auto;
  line-height: 100%;
  width: 355px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  border-width: 0;
  flex-direction: column;
}

.order-no {
  font-size: 12px;
  color: gray;
  margin-right: 10px;
  margin-bottom: 5px;
}

.order-shop-info {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.order-shop-sub-info {
  width: 100%;
}

.order-status {
  width: 100%;
  display: inline-block;
  font-size: 12px;
  color: white;
  margin-right: 10px;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.order-time-info {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  color: gray;
  width: 335px;
}

.loading {
  padding: 5px;
  text-align: center;
}

.loading:before {
  display: inline-block;
  margin-right: 2px;
  vertical-align: middle;
  content: '';
  width: 20px;
  height: 20px;
  /*background: url(../../images/icon-loading.png) no-repeat;*/
  background-size: contain;
  animation: rotate 1s linear infinite;
}

.loading.complete:before {
  display: none;
}

.unpayed-background-color {
  background-color: orangered;
}

.payed-background-color {
  background-color: #1296db;
}

.eleme-background-color {
  background-color: #1296db;
}
</style>
